import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import { graphql, navigate, useStaticQuery } from "gatsby"

import Button from "../common/forms/button"
import Input from "../common/forms/input"
import { useFormikContext } from "formik"
import InviteInputForm from "../common/invite-input-form"
import Img from "gatsby-image"
import RootContext from "../../contexts/RootContext"
import PulseLoader from "react-spinners/PulseLoader";

const Home = props => {
  const formik = useFormikContext()
  const { referrer, fetchUser, size, validating } = useContext(RootContext)

  const data = useStaticQuery(graphql`
    query {
      captain: file(sourceInstanceName: {eq: "common"}, name: {eq: "felix"}) {
        childImageSharp {
          fluid(maxWidth: 2200, quality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const getProps = name => ({
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    id: name,
    name: name,
    value: formik.values[name]
  });

  const handleSubmit = () => {
    formik.submitForm().then(() => {
      navigate('/details')
    })
  };

  useEffect(() => {
    if(props.username && !referrer)
      fetchUser(props.username)
  }, []);

  return (
    <Flex size={size}>
      <Title>
        Your Audio Sidekick
        <br />
        
      </Title>
        <StyledImage loading="eager" fluid={data.captain.childImageSharp.fluid} />
      <Subtitle>
        Felix is for those that want to bop and feel good.
      </Subtitle>
      <Form overlap={props.username}>
        {
          referrer ?
            <InviteInputForm validating={validating} referrer={referrer}/>
            :
            <>
              <Input onKeyDown={e => {
                if(e.key === 'Enter') {
                  handleSubmit()
                }
              }} {...getProps("email")} placeholder="Your email address" />
              <HomeButton onClick={handleSubmit} disabled={validating} type='submit'>
                {
                  validating ? <PulseLoader
                    size={10}
                    color={"#FFFFFF"}
                    loading={true}
                  /> : "Join Now"
                }
              </HomeButton>
            </>
        }
      </Form>
    </Flex>
  )
}

export default Home

const Title = styled.div`
  text-align: start;
  font-size: 6.5vh;
  line-height: 7vh;
  font-family: "GT Haptik Bold";
  font-weight: bold;
  margin: 0;
  @media (max-width: ${props => props.theme.screen.md}) {
    font-size: 5vh;
    line-height: 5.5vh;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    text-align: center;
    font-size: 28px;
    line-height: 34px;
  }
  @media only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    text-align: center;
  }
`

const HomeButton = styled(Button)`
  font-size: 18px;
  font-weight: bold;
  padding: 18px 40px;
  font-family: "GT Haptik Bold";
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    padding: 18px 40px;
  }
`

const Subtitle = styled.div`
  display: flex;
  font-size: 24px;
  line-height: 30px;
  font-family: "GT Haptik Light";
  margin: 2vh 0;
  @media (max-width: ${props => props.theme.screen.md}) {
    font-size: 20px;
    line-height: 26px;
  }
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    display: none;
  }
`

const Flex = styled.div`
  margin: 0;
  flex: 1;
  padding-left: 12vw;

  h1 {
    margin: 0 0 24px;
    font-weight: 900;
    color: ${props => props.theme.color.primary};
    font-family: "GT Haptik Bold";
  }

  h2 {
    margin-bottom: 24px;
    ${props => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
  
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    padding: 0 0;
    width: ${props => props.size.width}px;
  }
`

const Form = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  @media (max-width: ${props => props.theme.screen.md}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    width: auto;
    max-width: unset;
    padding: 0 7.5vw;
    margin-top: ${props => props.overlap ? '-8vh' : '-2vh'};
  }
`

const StyledImage = styled(Img)`
  display: none;
  z-index: -1;
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    display: block;
    width: ${props => props.referred ? '80%' : '100%'};
    height: 50vh;
    object-fit: contain;
  }
`

import React, { useContext, useEffect, useState, useRef } from "react"

import Layout from "../components/common/layout/layout"
import Home from "../components/sections/home"
import Details from "../components/sections/details"
import { Router } from "@reach/router"
import Personal from "../components/sections/details/personal"
import Audio from "../components/sections/details/audio"
import Habits from "../components/sections/details/habits"
import Summary from "../components/sections/summary"
import SnackbarProvider from 'react-simple-snackbar'
import { Formik } from "formik"
import * as Yup from "yup"
import RootContext, { withRootContext } from "../contexts/RootContext"
import { join, validate } from '../services'
import SEO from "../components/common/layout/seo"

const IndexPage = props => {
  const { referrer, setValidating, loadingReferrer } = useContext(RootContext)
  const formik = useRef();
  const [currentValues, setCurrentValues] = useState({})

  const isUnique = async (key, value) => {
    if(currentValues[key]?.value === value)
      return !currentValues[key]?.["exists"]

    setValidating(true);

    const { data: { result } } = await validate({[key]: value})
    setCurrentValues({...currentValues, [key]: { exists: result[key], value }})

    setValidating(false);
    return !result[key]
  }

  const DefaultValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Required")
      .test("checkEmail", "Email already registered!", async (value) => await isUnique("email", value))
  })

  const ValidationSchemas = {
    "/details": Yup.object().shape({
      nickname: Yup.string()
        .min(2, "Nickname is missing, or too short")
        .required("Nickname is missing, or too short"),
      username: Yup.string()
        .test("checkUsername", "Username already registered!", async (value) => await isUnique("username", value))
        .min(2, "Too Short!")
        .matches(/^[a-zA-Z0-9._]*$/, "Please use only letters (a-z), numbers, periods, or underscore")
        .required("Required"),
      phoneNumber: Yup.string()
        .required("Required"),
      "phoneNumber-code": Yup.string()
        .required("Required"),
    }),
    "/details/audio": Yup.object().shape({
      phone: Yup.array()
        .required("Required field"),
      airpods: Yup.array()
        .required("Also required.."),
      spotify: Yup.string()
        .required("Required, hook us up!"),
    }),
    "/details/habits": Yup.object().shape({
      song: Yup.string()
        .max(65)
        .required("Last song you liked on Spotify was?"),
      howLong: Yup.string()
        .max(148)
        .required("Ya, we wanna know"),
      survey: Yup.string()
        .required("Do it!")
    }),
  }

  // Begin -- Workaround for styled-components props loading issue on first load
  const [render, setRender] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => setRender(true), 100)
    return () => clearTimeout(timeout)
  }, [])
  if (!render) return null
  // End -- Workaround for styled-components props loading issue on first load

  if(loadingReferrer)
    return <div/>;

  return (
    <>
      <SEO title={referrer?.username ? `Invite from ${referrer?.nickname} (@${referrer?.username}) - Felix` : null} />
      <Formik
        innerRef={formik}
        initialValues={{
          email: "",
          nickname: "",
          username: "",
          phoneNumber: "",
          "phoneNumber-code": "+1",
          phone: [],
          airpods: [],
          spotify: [],
          howLong: "",
          song: "",
          survey: [],
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={ValidationSchemas[props.location.pathname] || DefaultValidationSchema}
        onSubmit={(values, { resetForm }) => {
          console.log("values:", values);
          console.log("window?.location?.pathname:", window?.location?.pathname);
          if(window?.location?.pathname?.endsWith('habits'))
            join({...values, referrer: referrer?.username})
              .then(res => {
                resetForm({})
                console.log("Join-Success:", res)
              })
              .catch(error => {
                console.log("Join-Error:", error)
              })
          else {
            console.log("values:", values)
          }
        }}
        displayName={'InfoForm'}
      >
        <SnackbarProvider>
          <Layout {...props} openSideNav={props.location.pathname.startsWith("/details")}>
            <Router basepath="/">
              <Home path="/"/>
              <Details path="/details">
                <Personal path="/"/>
                <Audio path="/audio"/>
                <Habits path="/habits"/>
                <Summary path="/summary"/>
              </Details>
              <Home path="/:username"/>
            </Router>
          </Layout>
        </SnackbarProvider>
      </Formik>
    </>
  )
}

export default withRootContext(IndexPage)

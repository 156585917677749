import React, { useEffect } from "react"
import styled from "styled-components"
import PropTypes from 'prop-types'
import { useFormikContext } from "formik"

const ChoiceGroup = props => {
  const selected = props.value.reduce((obj, item) => ({...obj, [item.key]: {selected: true, ...item}}), {})
  const {multiple, items, caption, error = 'primary', ...rest} = props

  const { errors, touched, setFieldTouched } = useFormikContext()

  useEffect(() => {
    setFieldTouched(rest.name, false);
  }, []);

  return (
    <Column>
      <Caption>{caption}</Caption>
      <Container>
        {
          items.map((item, index) =>
            <Button
              key={item.key}
              textTransform={item.textTransform}
              selected={selected[item.key]?.selected}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                const selectedItems = { ...(multiple ? selected : {}), [item.key]: {selected: !selected[item.key], ...item} };
                setFieldTouched(rest.name, true);
                props.onChange({
                  target: {
                    type: 'text',
                    name: rest.name,
                    value: Object.values(selectedItems).filter(item => item.selected)
                  }
                });
              }}>
              {item.name}
            </Button>
          )
        }
      </Container>
      {errors[rest.name] && touched[rest.name] && <Error {...rest} error={error}>{errors[rest.name]}</Error>}
    </Column>
  )
}

ChoiceGroup.propTypes = {
  caption: PropTypes.string,
  items: PropTypes.array,
  multiple: PropTypes.bool,
  value: PropTypes.array
}

ChoiceGroup.defaultProps = {
  value: []
}

export default ChoiceGroup

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`

const Caption = styled.div`
  margin: 4px 0;
  font-size: 2vh;
  font-weight: bold;
`

const Error = styled.div`
  position: ${props => props.staticError ? 'absolute' : 'unset'};
  bottom: ${props => props.staticError ? '-2.5vh' : 'unset'};
  color: ${props => props.error === 'secondary' ? 'white' : props.theme.color.warning};
  opacity: 0.8;
  text-transform: capitalize;
  margin: 1vh 0;
  font-size: 12px;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  * {
    margin: 8px 0 16px 0;
    margin-right: 16px;
  }
`

const Button = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.selected ? props.theme.button.color : props.theme.button.secondary.color};
  letter-spacing: 1px;
  height: 60px;
  display: block;
  cursor: pointer;
  white-space: nowrap;
  font-weight: ${props => props.theme.button.fontWeight};
  text-shadow: ${props => props.selected ? props.theme.button.textShadow : props.theme.button.secondary.textShadow};
  background: ${props => props.selected ? props.theme.choice.background : props.theme.button.secondary.background};
  border-radius: ${props => props.theme.button.borderRadius};
  box-shadow: ${props => props.selected ? props.theme.choice.boxShadow : props.theme.button.secondary.boxShadow};
  padding: 0px 20px;
  text-transform: ${props => props.textTransform ?? 'capitalize'};
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

import { css } from "styled-components"

export const inputStyles = css`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  line-height: 32px;
  font-family: "GT Haptik";
  text-align: left;
  background: ${props => props.error ? props.theme.input.error.background : props.theme.input.background};
  border: ${props => props.error ? props.theme.input.error.border : props.theme.input.border};
  border-radius: ${props => props.theme.input.borderRadius};
  box-shadow: ${props => props.theme.input.boxShadow};
  background-clip: padding-box;
  padding: 12px 16px;
  margin-bottom: ${props => props.staticError ? '28px' : '0px'};
  ::placeholder,
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 500;
    color: ${props => props.theme.input.hint};
    opacity: 0.5;
  }
  
  @media (max-width: ${props => props.theme.screen.sm}) {
    padding: 10px 16px;
  }
`

import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import countryCodes from '../../../static/jsons/country-codes.json'
import { inputStyles } from "./styles"

const CountryCodesDropDown = props => {

  return (
    <Select {...props} value={props.value && props.value.length > 0 ? props.value : countryCodes[0].dial_code}>
      {
        countryCodes.map((code, index) => (
          <option key={index.toString()} value={code.dial_code}>{code.dial_code}</option>
        ))
      }
    </Select>
  )
}

const Select = styled.select`
  ${inputStyles};
  padding: 12px 0 12px 12px;
  width: auto;
`

CountryCodesDropDown.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
}

CountryCodesDropDown.defaultProps = {
  onChange: () => {},
  value: ""
}

export default CountryCodesDropDown

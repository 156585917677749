import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import CountryCodesDropDown from "./country-codes"
import { inputStyles } from "./styles"
import { useFormikContext } from "formik"

const Input = styled.input`
  ${inputStyles};
  flex: 1;
`

const PhoneInput = props => {
  const { label, onChangeNumber, onChangeCode, valueCode, valueNumber, error = 'primary', ...rest } = props
  const { errors, touched } = useFormikContext()

  return (
    <Container>
      {
        label && <LabelContainer>
          <Label>
            {label}
          </Label>
        </LabelContainer>
      }
      <Row>
        <CountryCodesDropDown {...rest} id={`${rest.id}-code`} name={`${rest.name}-code`} value={valueCode} onChange={onChangeCode}/>
        <Input error={errors[rest.name] && touched[rest.name]} pattern="[0-9]*" type='text' inputmode="numeric" value={valueNumber} onChange={onChangeNumber} {...rest} />
      </Row>
      {errors[rest.name] && touched[rest.name] && <Error {...rest} error={error}>{errors[rest.name]}</Error>}
    </Container>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  > *:not(:last-child) {
    margin-right: 8px;
  }
`

const Error = styled.div`
  position: ${props => props.staticError ? 'absolute' : 'unset'};
  bottom: ${props => props.staticError ? '-0.5vh' : 'unset'};
  color: ${props => props.error === 'secondary' ? 'white' : props.theme.color.warning};
  opacity: 0.8;
  text-transform: capitalize;
  margin: 1vh 0;
  font-size: 12px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
`

const Label = styled.div`
  color: ${props => props.theme.color.primary};
  font-size: 2vh;
  font-weight: bold;
  padding: 1vh 0;
`

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

PhoneInput.propTypes = {
  label: PropTypes.string,
  onChangeNumber: PropTypes.func,
  onChangeCode: PropTypes.func,
  valueNumber: PropTypes.string,
  valueCode: PropTypes.string,
}

PhoneInput.defaultProps = {
  onChangeNumber: () => {},
  onChangeCode: () => {},
  valueNumber: "",
  valueCode: "+1",
}

export default PhoneInput

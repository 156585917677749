import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const SummaryDialog = props => {
  return <Container {...props.style}>
    <div className='header'>
      Summary
    </div>
    {
      props.items.map((item, index) => (
        <ItemContainer key={index.toString()}>
          <Label>{item.label}</Label>
          <Value>{item.value}</Value>
        </ItemContainer>
      ))
    }
  </Container>
}

SummaryDialog.propTypes = {
  items: PropTypes.array
}

const ItemContainer = styled.div`
  padding: 1vh 0;
`

const Label = styled.div`
  color: white;
  opacity: 0.5;
  padding: 1vh 0;
`

const Value = styled.div`
  color: white;
  background: linear-gradient(180deg, #FFFFFF55 0%, #E5E5E555 100%);
  border: 1px solid rgba(22, 68, 94, 0.1);
  box-shadow: 0px 2px 8px rgba(22, 68, 94, 0.12);
  border-radius: 20px;
  font-size: 2vh;
  padding: 12px 16px;
`

const Container = styled.div`
  border-radius: 16px;
  background: linear-gradient(180deg, #5992FF 2.25%, #2262DD 97.75%);
  padding: 3vh 2vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  
  .header {
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 3vh;
    line-height: 25px;
    color: white;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 3vh 16px;
  }
`

export default SummaryDialog;

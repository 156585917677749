import React from "react"
import styled from "styled-components"
import ChoiceGroup from "../../common/forms/choice"
import { useFormikContext } from "formik"

const Audio = () => {

  const formik = useFormikContext()

  const getProps = name => ({
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    id: name,
    name: name,
    value: formik.values[name]
  })

  return (
    <Flex>
      <Title>
        Crafting your audio vibe
      </Title>
      <Form>
        <ChoiceGroup
          staticError
          {...getProps("phone")}
          caption='What is your device situation?'
          items={[
            {
              name: "iPhone",
              key: "iphone",
              textTransform: 'unset'
            },
            {
              name: "Android",
              key: "android"
            }
          ]}
          multiple={true}/>
        <ChoiceGroup
          staticError
          {...getProps("airpods")}
          caption='Got the AirPods life?'
          items={[
            {
              name: "Nope",
              key: "nope"
            },
            {
              name: "AirPods 1/2",
              key: "airpods_1",
            },
            {
              name: "AirPods Pro",
              key: "airpod_2"
            },
            {
              name: "AirPods Max",
              key: "airpods_pro"
            }
          ]}/>
        <ChoiceGroup
          staticError
          {...getProps("spotify")}
          caption='Rocking Spotify?'
          items={[
            {
              name: "Nah",
              key: "nope",
            },
            {
              name: "Sometimes",
              key: "sometimes"
            },
            {
              name: "Always on it",
              key: "chronic"
            }
          ]}/>
      </Form>
    </Flex>
  )
}

export default Audio

const Title = styled.h2`
  font-size: 24px;
  line-height: 32px;
  text-align: start;
  @media (max-width: ${props => props.theme.screen.md}) {
    text-align: center;
  }
`

const Flex = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  grid-template-columns: 1fr 1fr;
  margin: 0 4vw;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  > * {
    margin-bottom: 3vh;
  }
`
import React, { useContext } from "react"
import styled from "styled-components"
import Button from "./forms/button"
import PropTypes from "prop-types"
import RootContext from "../../contexts/RootContext"
import PulseLoader from "react-spinners/PulseLoader"

const OnboardingProgress = props => {
  const { validating } = useContext(RootContext)

  return <Container>
    <BackButton onClick={props.onPrev}>
      Back
    </BackButton>
    <StepsContainer>
      {props.step}/{props.total}
    </StepsContainer>
    <NextButton disabled={props.disabled} onClick={props.onNext}>
      {
        validating ? <PulseLoader
          size={6}
          color={"#FFFFFF"}
          loading={true}
        /> : "Next"
      }
    </NextButton>
  </Container>
}

OnboardingProgress.propTypes = {
  step: PropTypes.number,
  total: PropTypes.number,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  disabled: PropTypes.bool
}

OnboardingProgress.defaultProps = {
  total: 3,
  step: 1,
  onNext: () => {},
  onPrev: () => {},
}

const NextButton = styled(Button)`
  height: auto;
  padding: 16px 24px;
  border-radius: 16px;
  margin: 0;
`

const StepsContainer = styled.span`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`

const BackButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: block;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  font-weight: ${props => props.theme.button.fontWeight};
  background: transparent;
  padding: 0.6vh 1.2vw;
  color: ${props => props.theme.color.primary};
  opacity: 0.5;
  font-family: "GT Haptik Bold";
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    margin-left: 0;
  }
`

const Container = styled.div`
  border: 1px ${props => props.theme.color.grey.light} solid;
  border-radius: 20px;
  padding: 12px 1vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: unset;
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    flex: 1;
    padding: 8px 16px;
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;
    border-bottom: unset;
    margin: 0 -1px;
    background: linear-gradient(180deg, #FFFFFF 0%, #E5E5E5 100%);
  }
`

export default OnboardingProgress;

import React from "react"
import styled from "styled-components"
import { useFormikContext } from "formik"

import Input from "../../common/forms/input"
import PhoneInput from "../../common/forms/phone-input"

const Personal = () => {

  const formik = useFormikContext()

  return (
    <Flex>
      <Title>
        Your ears will thank you. Fill out the deets below:
      </Title>
      <Form onSubmit={formik.handleSubmit}>
        <Input focus staticError maxLength="50" label='Nickname' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.nickname}
               id='nickname' name='nickname' placeholder="Tim Tim"/>

        <Input staticError label='Username' maxLength="15" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.username}
               id='username' name='username' placeholder="@handle"/>

        <PhoneInput staticError label='Phone Number' onBlur={formik.handleBlur} onChangeCode={formik.handleChange}
                    onChangeNumber={formik.handleChange} valueCode={formik.values['phoneNumber-code']} valueNumber={formik.values.phoneNumber} id='phoneNumber'
                    name='phoneNumber' placeholder="123-456-7890"/>
      </Form>
    </Flex>
  )
}

export default Personal

const Title = styled.h2`
  font-size: 24px;
  line-height: 32px;
  text-align: start;
  @media (max-width: ${props => props.theme.screen.md}) {
    text-align: center;
  }
`

const Flex = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin: 0 4vw;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`

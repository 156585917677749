import React, { useContext, useState, useEffect } from "react"
import styled from "styled-components"
import OnboardingProgress from "../../common/onboarding-progress"
import { graphql, navigate, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { useFormikContext } from "formik"
import RootContext from "../../../contexts/RootContext"
import Button from "../../common/forms/button"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { withSnackbar } from "react-simple-snackbar"
import theme from '../../../styles/theme'

const ROUTE_LIST = [
  "/details",
  "/details/audio",
  "/details/habits",
  '/details/summary'
]

const ROUTES = ROUTE_LIST.reduce((obj, item, index) => ({...obj, [item]: index}), {})

const Details = props => {
  const formik = useFormikContext()
  const path = props.location.pathname
  const step = ROUTES[path]
  const context = useContext(RootContext)
  const [username, setUsername] = useState(null)
  const url = `${window.location.host}/${username}`
  const copyUrl = `${window.location.origin}/${username}`
  const { openSnackbar } = props

  const data = useStaticQuery(graphql`
    query {
      felixLogo: file(sourceInstanceName: {eq: "common"}, name: {eq: "felix-logo"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  useEffect(() => {
    if(formik.values.username)
      setUsername(formik.values.username)
  }, [formik.values])

  const onCopy = () => {
    console.log('Copied!')

    if (navigator.share) {
      navigator.share({
        title: 'web.dev',
        text: `Join Felix. Feel good, stay connected with your close friends and fam.`,
        url: copyUrl,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      openSnackbar('Copied to clipboard!', 2500)
    }
  }

  const setTouched = () => Object.keys(formik.errors).map(key => {
    console.log(`${key}=${true}`)
    formik.setFieldTouched(key, true)
  })

  return (
    <Container size={context.size} step={step}>
      <Content step={step}>
        <LogoContainer>
          <LogoImage fluid={data.felixLogo.childImageSharp.fluid} />
        </LogoContainer>
        {props.children}
      </Content>
      {
        step < ROUTE_LIST.length - 1 ? <Footer>
          <OnboardingProgress
            step={ROUTES[path] + 1}
            onNext={() => {
              setTouched()
              formik.submitForm().then(() => {
                formik.setTouched({})
                navigate(ROUTE_LIST[(step + 1) % ROUTE_LIST.length])
              }).catch(err => {
                console.log("Error:", err)
              })
            }}
            onPrev={() => {
              formik.setErrors({})
              if(step === 0)
                navigate(`/${context.referrer ?? ""}`)
              else
                navigate(ROUTE_LIST[(step - 1) % ROUTE_LIST.length])
            }}
            total={ROUTE_LIST.length - 1}/>
        </Footer> : <FooterSummary>
          <ShareLinkContainer>
            <div className='label'>
              Your Invite Link:
            </div>
            <div className='url'>{url}</div>
          </ShareLinkContainer>
          <CopyToClipboard text={copyUrl}
                           onCopy={onCopy}>
            <InviteButton>
              Share With Your Besties
            </InviteButton>
          </CopyToClipboard>
          <CopyToClipboard text={copyUrl}
                           onCopy={onCopy}>
            <InviteButtonSM>
              Share
            </InviteButtonSM>
          </CopyToClipboard>
        </FooterSummary>
      }
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${props => props.size?.height}px;
  padding: ${props => props.step === ROUTE_LIST.length - 1 ? '0vw' : '8vw 2vw'};
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    width: 100vw;
    padding: 0;
  }
`

const LogoContainer = styled.div`
  display: none;
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    display: flex;
    padding-top: 8px;
    flex-direction: row;
    justify-content: center;
  }
`

const LogoImage = styled(Img)`
  width: 15%;
`

const Content = styled.div`
  display: flex;
  flex: unset;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    flex: 1;
    overflow: scroll;
  }
`

const InviteButtonStyle = styled(Button)`
  margin: 0 0;
  padding: 12px 18px;
  height: auto;
  border-radius: 12px;
  text-shadow: none;
  background: linear-gradient(180deg, #FFF494 0%, #F9E000 100%);
  border: 1px solid rgba(22, 68, 94, 0.1);
  box-shadow: 0 2px 8px rgba(22, 68, 94, 0.12);
  color: ${props => props.theme.color.primary};
  text-transform: capitalize;
  font-family: "GT Haptik Bold";
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    font-size: 12px;
  }
`

const InviteButton = styled(InviteButtonStyle)`
  display: flex;
  padding: 8px 18px;
  border-radius: 10px;
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    display: none;
  }
`

const InviteButtonSM = styled(InviteButtonStyle)`
  display: none;
  padding: 10px 22px;
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    display: flex;
  }
`

const ShareLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .label {
    font-size: 12px;
    opacity: 0.5;
    color: white;
  }
  .url {
    font-family: "GT Haptik";
    font-weight: bold;
    font-size: 18px;
    color: white;
  }
`

const FooterSummary = styled.div`
  position: absolute;
  bottom: 0;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  background: linear-gradient(180deg, #A277FE 0%, #6126DF 100%);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  width: 100%;
  display: flex;
  padding: 1vh 2vw 1.8vh 2vw;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 1vh 24px;
  }
`

const Footer = styled.div`
  margin: 0 4vw;
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    margin: 0 0;
  }
`

export default withSnackbar(Details, {
  position: 'top-right',
  style: {
    background: theme.snackbar.background,
    fontWeight: 'bold',
    fontFamily: 'GT Haptik'
  },
})

import React from "react"
import styled from "styled-components"

import Input from "../../common/forms/input"
import ChoiceGroup from "../../common/forms/choice"
import { useFormikContext } from "formik"

const Habits = () => {

  const formik = useFormikContext()

  const getProps = name => ({
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    id: name,
    name: name,
    value: formik.values[name]
  })

  return (
    <Flex>
      <Title>
        Homestretch. Just a few more qs:
      </Title>
      <Form>
        <Input focus staticError label='How long does it take you to get work-ready?'
               placeholder="*Yawn* I usually.."
               lines={2}
               allowedLimit={148}
               {...getProps("howLong")}
               showCount/>
        <Input staticError {...getProps("song")} label='What is that one bop that always puts you in a good mood?' placeholder="Your jam 🎵"/>
        <ChoiceGroup
          staticError
          {...getProps("survey")}
          caption='Are you down for a survey and early product drops?'
          items={[
            {
              name:"Yup",
              key: "yes"
            },
            {
              name: "Maybe",
              key: "maybe",
            },
            {
              name: "Nah",
              key: "no"
            }
          ]}/>
      </Form>
    </Flex>
  )
}

export default Habits

const Title = styled.h2`
  font-size: 24px;
  line-height: 32px;
  text-align: start;
  @media (max-width: ${props => props.theme.screen.md}) {
    text-align: center;
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin: 0 4vw;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  > * {
    margin-bottom: 2vh;
  }
`

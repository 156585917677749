import React, { useState } from "react"
import styled from "styled-components"
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from "gatsby"
import SummaryDialog from "../common/summary-dialog"
import { useFormikContext } from "formik"

const Summary = () => {
  const formik = useFormikContext();
  const [result] = useState(formik.values);
  const data = useStaticQuery(graphql`
    query {
      celebrationsBack: file(sourceInstanceName: {eq: "backgrounds"}, name: {eq: "celebrations"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      colorStripes: file(sourceInstanceName: {eq: "backgrounds"}, name: {eq: "color-bars"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
    }
  `)

  return (
    <Root>
      <Container>
        <H1>
          Turn Up!
        </H1>
        <Subtitle>
          Audio squad bound. Added to Felix.fm waitlist, congrats.
        </Subtitle>
        <SummaryContainer>
          <SummaryDialog items={[
            {
              label: 'Your email',
              value: result.email
            },
            {
              label: 'Reserved Username',
              value: result.username
            },
            {
              label: 'Your fav song',
              value: result.song
            }
          ]}/>
        </SummaryContainer>
        <SkipInvitePrompt>
          Cut the line by bringing your bestie and a few close homies along.
        </SkipInvitePrompt>
        <StripesContainer>
          <Img fluid={data.colorStripes.childImageSharp.fluid}/>
        </StripesContainer>
      </Container>
      <CelebrationsBack>
        <Img fluid={data.celebrationsBack.childImageSharp.fluid}/>
      </CelebrationsBack>
    </Root>
  )
}

const Container = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 2vh 3vw;
  @media (max-width: ${props => props.theme.screen.sm}), 
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    width: 100vw;
    padding: 0.5vh 3vw;
  }
`

const SummaryContainer = styled.div`
  margin: 2vh 4vw;
`

const StripesContainer = styled.div`
  margin: 0 30%;
  @media (max-width: ${props => props.theme.screen.md}) {
    margin: 0 15%;
  }
`

const SkipInvitePrompt = styled.p`
  font-family: "GT Haptik Light";
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 36px;
  padding: 0 3vw;
  @media (max-width: ${props => props.theme.screen.md}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    text-align: center;
    font-size: 3vh;
    line-height: 3.5vh;
    padding: 0 6vw;
  }
`

const H1 = styled.h1`
  font-weight: bold;
  font-size: 4vw;
  text-align: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    text-align: center;
    font-size: 32px;
    line-height: 31px;
  }
`

const Subtitle = styled.p`
  font-family: "GT Haptik Light";
  font-size: 1.6vw;
  font-weight: normal;
  font-style: normal;
  line-height: 4vh;
  text-align: center;
  margin: 0 4vw;
  @media (max-width: ${props => props.theme.screen.md}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    text-align: center;
    font-size: 3vh;
    line-height: 3.5vh;
    padding: 0 3vw;
  }
`

const CelebrationsBack = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
`

const Root = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

export default Summary

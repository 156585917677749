import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { inputStyles } from "./styles"
import { useFormikContext } from "formik"

const Input = styled.input`
  ${inputStyles};
  flex: 1;
`

const TextArea = styled.textarea`
  ${inputStyles};
  resize: vertical;
  max-height: 30vh;
`

const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

  return [ htmlElRef, setFocus ]
}

const TextInput = props => {
  const { label, lines, showCount, onChange, allowedLimit, value, focus=false, error = 'primary', ...rest } = props

  const Element = lines ? TextArea : Input

  const { errors, touched } = useFormikContext()
  const [inputRef, setInputFocus] = useFocus()

  useEffect(() => {
    if(focus)
      setInputFocus(true)
  }, [])

  return (
    <Container>
      {
        label && <LabelContainer>
          <Label>
            {label}
          </Label>
          {showCount && <CountLabel>
            {value.length}/{allowedLimit}
          </CountLabel>}
        </LabelContainer>
      }
      <Element ref={inputRef} error={errors[rest.name] && touched[rest.name]} value={value} onChange={e => {
        if(e.target.value.length >= allowedLimit + 1)
          return
        onChange(e)
      }} rows={lines} {...rest} />
      {errors[rest.name] && touched[rest.name] && <Error {...rest} error={error}>{errors[rest.name]}</Error>}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
`

const Label = styled.div`
  color: ${props => props.theme.color.primary};
  font-size: 2vh;
  font-weight: bold;
  padding: 1vh 0;
`

const CountLabel = styled(Label)`
  font-family: "GT Haptik";
  font-size: 12px;
  width: 60px;
  text-align: right;
  line-height: 18px;
  opacity: 0.8;
`

const Error = styled.div`
  position: ${props => props.staticError ? 'absolute' : 'unset'};
  bottom: ${props => props.staticError ? '-0.5vh' : 'unset'};
  color: ${props => props.error === 'secondary' ? 'white' : props.theme.color.warning};
  opacity: 0.8;
  margin: 1vh 0;
  font-size: 12px;
`

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

TextInput.propTypes = {
  label: PropTypes.string,
  lines: PropTypes.number,
  allowedLimit: PropTypes.number,
  showCount: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

TextInput.defaultProps = {
  lines: null,
  showCount: false,
  allowedLimit: 512,
  onChange: () => {},
  value: ""
}

export default TextInput

import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { useFormikContext } from "formik"
import Input from "./forms/input"
import Button from "./forms/button"
import { navigate } from "gatsby"
import PulseLoader from "react-spinners/PulseLoader"

const InviteInputForm = props => {
  const formik = useFormikContext()

  const getProps = name => ({
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    id: name,
    name: name,
    value: formik.values[name]
  })

  const handleSubmit = () => {
    formik.submitForm().then(() => {
      navigate('/details')
    })
  }

  return <Container {...props.style}>
    <Caption>
      A lit invite from <b>{props.referrer.nickname}</b> (@<u>{props.referrer.username}</u>). Join Felix, reserve your spot.
    </Caption>
    <Input onKeyDown={e => {
      if (e.key === 'Enter') {
        handleSubmit()
      }
    }} error='secondary' {...getProps("email")} placeholder="Your email address" />
    <InviteButton onClick={handleSubmit} disabled={props.validating}>
      {
        props.validating ? <PulseLoader
          size={10}
          color={"#FFFFFF"}
          loading={true}
        /> : "Join Now"
      }
    </InviteButton>
  </Container>
}

InviteInputForm.propTypes = {
  referrer: PropTypes.shape({
    username: PropTypes.string,
    nickname: PropTypes.string
  }),
  onSubmit: PropTypes.func
}

InviteInputForm.defaultProps = {
  referrer: 'Kenny Grant'
}

const Caption = styled.div`
  color: #FFFFFF;
  text-align: center;
  font-size: 18px;
  margin: 1vh 0;
  font-family: "GT Haptik Light";

  b {
    font-family: "GT Haptik Bold";
  }
`

const InviteButton = styled(Button)`
  margin-top: 18px;
  font-size: 18px;
  font-family: "GT Haptik Bold";
`

const Container = styled.div`
  border-radius: 16px;
  background: linear-gradient(180deg, #5992FF 2.25%, #2262DD 97.75%);
  padding: 2vh 1vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .header {
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 3vh;
    line-height: 25px;
    color: white;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    padding: 20px 16px;
  }
`

export default InviteInputForm;
